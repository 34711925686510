.cursor-pointer {
  cursor: pointer;
}

.bg-black-50 {
  background-color: rgba($color: #000000, $alpha: 0.5);
}

.bg-black {
  background-color: rgba($color: #000000, $alpha: 1);
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.verify-email-alert {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: $zindex-fixed;
}

.firebase-emulator-warning {
  width: auto !important;
  right: 0 !important;
  left: auto !important;
}

.player-controls-play-button {
  font-size: 5.625em;
}

.player-wrapper {
  font-size: 10px;
  margin: 0 auto;

  @media (min-width: map-get($map: $grid-breakpoints, $key: sm)) {
    font-size: 1em;
  }
}

.player {
  .player-container {
    position: relative;

    video {
      max-width: 100%;
      display: block;
    }
  }
}

.player-preview {
  position: relative;
  background-color: #000;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  user-select: none;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }

  .player-controls-control-bar-timeline
    .control-bar-progress-bar
    .progress-bar-bar
    .progress-bar-indicator {
    width: 30%;
  }
}
